<template>
  <div class="videos-container">
    <v-row>
      <v-col cols="12" sm="6">
        <div class="video-title">So funktioniert Super Rent</div>
        <vue-player video
                    poster="https://superrent.at/images/VIDEO_SO_FUNKTIONIERT.png"
                    :sources="{ 'video/mp4': 'https://superrent.at/videos/Superrent_so_funktionierts.mp4' }"/>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="video-title">Das sind wir</div>
        <vue-player video
                    poster="https://superrent.at/images/VIDEO_ABOUT_US.png"
                    :sources="{ 'video/mp4': 'https://superrent.at/videos/Superrent_Vorstellungsvideo.mp4' }"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VuePlayer from '@iomariani/vue-player';
export default {
  name: 'Videos',
  components: {
    VuePlayer
  }
}
</script>

<style lang="scss">
@import "@iomariani/vue-player/src/scss/vue-player.scss";
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/layout.scss";
.videos-container {
  .video-title {
    color: $primary;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .video-player .player-controls {
    background-color: transparent !important;
  }
  .player .player-wrapper {
    background-color: transparent !important;
  }
  .player .player-controls .player-tracker {
    color: black;
  }
}
</style>
